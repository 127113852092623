import React from "react"
import { graphql } from "gatsby"
import { defineCustomElements as deckDeckGoHighlightElement } from '@deckdeckgo/highlight-code/dist/loader';
//import "katex/dist/katex.min.css" //moved to gatsby-browser
import {
  LinkedinShareButton,
  FacebookShareButton,
  TwitterShareButton,
} from "react-share"

import Layout from "../components/layout"
import SEO from "../components/seo"
import RecommendedPosts from "../components/recommendedposts"
import Comments from "../components/comments"
//import ViewCounter from "../components/ViewCounter"

import * as S from "../components/post/styled"

deckDeckGoHighlightElement(); //styled codeblock highlight

const BlogPost = ({ data, pageContext }) => {
  const post = data.markdownRemark
  const next = pageContext.next
  const previous = pageContext.previous

  const timeToReadPost = Math.ceil(post.fields.readingTime.minutes)

  const siteTitle = data.site.siteMetadata.title

  const url = data.site.siteMetadata.siteUrl //used in canonical SEO
  const path = post.fields.slug //used in canonical SEO

  return (
    <Layout location={path} title={siteTitle}>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description}
        image={post.frontmatter.image}
        pathname={path}
      />
      <S.PostHeader>
        <S.PostDate>
          {post.frontmatter.date} <S.PostClock /> {timeToReadPost} min de leitura
        </S.PostDate>
        <S.PostTitle>{post.frontmatter.title}</S.PostTitle>
        <S.PostDate>
          Por: {data.site.siteMetadata.author} <br /><br />
        </S.PostDate>
        <S.PostDescription>{post.frontmatter.description}</S.PostDescription>
      </S.PostHeader>
      <S.MainContent>
        <div dangerouslySetInnerHTML={{ __html: post.html }}></div>
        <S.PostShare><S.Heart>❤</S.Heart><S.ShareText>Share</S.ShareText><S.Line><S.LineFill /></S.Line>
          <S.ShareSocialWrapper>
            <TwitterShareButton url={`${url}/${path}`} title={post.frontmatter.title}>
              <S.ShareTwitter />
            </TwitterShareButton>
            <LinkedinShareButton url={`${url}/${path}`} title={post.frontmatter.title}>
              <S.ShareLinkedin />
            </LinkedinShareButton>
            <FacebookShareButton url={`${url}/${path}`} title={post.frontmatter.title}>
              <S.ShareFacebook />
            </FacebookShareButton>
          </S.ShareSocialWrapper>
        </S.PostShare>
      </S.MainContent>
      <RecommendedPosts next={next} previous={previous} />
      <Comments url={post.fields.slug} title={post.frontmatter.title} />
    </Layout>
  )
}

export const query = graphql`
  query Post($slug: String!) {
    site {
      siteMetadata {
        title
        author
        siteUrl
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      fields {
        slug
        readingTime {
          minutes
          text
          time
          words
        }
      }
      frontmatter {
        title
        description
        date(locale: "pt-br", formatString: "DD MMM YYYY")
        image
      }
      html
      timeToRead
    }
  }
`

export default BlogPost