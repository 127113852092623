import styled from "styled-components"
import media from "styled-media-query"

import { FiClock } from "react-icons/fi";
//import { Clock } from '@styled-icons/fa-regular' /*viewbox too large */
import { LinkedinWithCircle } from '@styled-icons/entypo-social'
import { FacebookCircle, Twitter } from '@styled-icons/boxicons-logos'

import * as V from '../../styles/variables'

const Icon = `
  padding-top: .25rem;

  ${media.lessThan("medium")`
    padding-top: .25rem;
  `}
`

export const PostClock = styled(FiClock)`
  ${Icon}
  display:inline-block;
  color: var(--shellText);
`

export const PostHeader = styled.header`
  color: var(--postColor);
  margin: auto;
  max-width: 70rem;
  padding: 5rem 5rem 0;

  ${media.lessThan("medium")`
    padding: 3rem 0 0;
    max-width: 100%;
  `}
`

export const PostTitle = styled.h1`
  font-size: 4rem;
  font-weight: 700;
  padding: 0 1.4rem;
  margin: 1rem auto;

  ${media.lessThan("medium")`
    font-size: 2.8rem;
    line-height: 1.1;
    padding: 0 1rem;
  `}
`

export const PostDescription = styled.h2`
  font-size: 2rem;
  font-weight: 200;
  padding: 0 1.4rem;

  ${media.lessThan("medium")`
    font-size: 1.6rem;
    line-height: 1.3;
    padding: 0 1rem;
  `}
`

export const PostDate = styled.p`
  font-size: 1.1rem;
  font-weight: 400;
  padding: 0 1.4rem;

  ${media.lessThan("medium")`
    padding: 0 1rem;
  `}
`

export const MainContent = styled.article`
  .scroll {
  width: 20px;
  height: 200px;
  overflow: auto;
  float: left;
  margin: 0 10px;
  }
  .scroll1::-webkit-scrollbar {
    width: 10px;
  }
  .scroll1::-webkit-scrollbar-thumb {
    background: #666;
    border-radius: 20px;
  }
  .scroll1::-webkit-scrollbar-track {
    background: #ddd;
    border-radius: 20px;
  }

  --deckgo-highlight-code-carbon-header-button-width: 8px;
  --deckgo-highlight-code-carbon-header-button-height: 8px;
  --deckgo-highlight-code-carbon-header-padding: 0.75rem;

  deckgo-highlight-code{
    --deckgo-highlight-code-font-family: monospace;
    --deckgo-highlight-code-line-numbers: #999;
    --deckgo-highlight-code-scroll: hidden;
  } /* styled code highlight overriding prismjs from gatsby 
        not 100% tho, looking forward Carbon style using gatsby lib TODO*/

  .deckgo-highlight-code{
    ${media.lessThan("medium")`
      padding: 0;
    `}
  }

  margin: auto;
  max-width: 70rem;
  padding: 2rem 5rem;

  ${media.lessThan("medium")`
    padding: 2rem 0;
    max-width: 100%;
  `}

  p,
  h1,
  h2,
  h3,
  h4,
  ul,
  ol,
  .tags,
  iframe,
  .button-post {
    color: var(--postColor);
    font-size: 1.235rem;
    text-align: justify;
    font-weight: 400;
    line-height: 1.6;
    letter-spacing: 0.069rem;
    padding: 0 1.4rem;

    ${media.lessThan("medium")`
      padding: 0 1rem;
      word-break: break-word;
      text-align: left;
    `}
  }
  p {
    display: block;
    //text-align: justify;
    font-weight: 400;
    margin: 0 auto 1.6rem;
    em {
      display: block;
      text-align: center;
      font-size: .9rem;
    }
    strong {
      em {
        text-align: justify;
        font-size: 1.235rem;
        display: inline-block;
      }
    }
  }
  h1,
  h2,
  h3,
  h4,
  h5 {
    margin: 2.4rem auto 1rem;
  }
  ul,
  ol {
    list-style: disc;
    padding-left: 2.5rem;
    margin: 0 auto 1.6rem;
  }
  li {
    padding: 0.625rem 0;
    & > ul {
      margin-bottom: 0;
    }
  }
  p,
  li {
    code {
      word-wrap: break-word;
    }
  }
  img {
    display: block;
    max-width: 100%;
    margin: 1.875rem auto;
    margin-bottom: 5px;
    border-radius: 0.3rem;
    filter: drop-shadow(0 0.2rem 0.55rem var(--postImgShadow));
  }
  iframe {
    padding: 0 1.6rem 1.6rem;
    width: 100%;

    ${media.lessThan("medium")`
      padding: 0 1rem;
    `}
  }
  blockquote {
    color: var(--postColor);
    border-left: 0.3rem solid var(--highlight);
    padding: 0 1.875rem;
    margin: 3.125rem auto;
  }
  hr {
    border: 1px solid var(--borders);
    margin: 3rem auto;
  }
  #twitter-widget-0,
  .instagram-media,
  .twitter-tweet {
    margin: 20px auto !important;
  }
  h1,
  h2,
  h3,
  h4,
  h5 {
    font-weight: 800;
    letter-spacing: 0.069rem;
    line-height: 1.4;
  }
  h1 {
    font-size: 2.8rem;

    ${media.lessThan("medium")`
      font-size: 1.875rem;
    `}
  }
  h2 {
    font-size: 2.1rem;

    ${media.lessThan("medium")`
      font-size: 1.375rem;
    `}
  }
  h3 {
    font-size: 1.6rem;

    ${media.lessThan("medium")`
      font-size: 1.125rem;
    `}
  }
  h4 {
    font-size: 1.4rem;
  }
  h5 {
    font-size: 1.2rem;
  }
  strong {
    font-weight: 700;
  }
  .gatsby-resp-image-background-image {
    z-index: 2;
    opacity: 1 !important;
    border-radius: 0.3rem;
  }
  .gatsby-resp-image-image {
    box-shadow: none !important;
    transition: opacity 0.2s;
    border-radius: 0.3rem;

    &.lazyload {
      opacity: 0;
      border-radius: 0.3rem;
    }
    &.lazyloaded {
      opacity: 1;
      z-index: 3;
      border-radius: 0.3rem;
    }
  }
  .gatsby-highlight {
    padding: 0 1.6rem 1.6rem;

    ${media.lessThan("medium")`
      padding: 0;
    `}
  }
  .instagram-media {
    margin: 1rem auto !important;
  }
  a {
    border-bottom: 1px dotted var(--menuHover);
    color: var(--menuHover);
    text-decoration: none;
    transition: opacity 0.5s;
    svg {
      color: var(--postColor);
    }
    &:hover {
      opacity: 0.8;
    }
  }
  .gatsby-resp-image-figcaption {
    text-align: center;
  }
`

export const PostShare = styled.div`
  margin-top: 3rem;
  font-size: 1.5rem;
  color: var(--shellText);
  max-width: 70rem;
  box-sizing: border-box;
  -webkit-box-flex: 1;
  flex-grow: 1;
  flex-wrap: wrap;
  display: flex;
  margin-right: -0.5rem;
  margin-left: -0.5rem;
  -webkit-box-align: center;
  align-items: center;

  ${media.lessThan("medium")`
    padding-left: ${V.Space.sm};
    padding-right: ${V.Space.sm};
  `}
`

export const Heart = styled.div`
  @keyframes heartpump {
    to {
      text-shadow: 0 0 10px #FF5252, 0 0 20px #FF5252;
    }
  }
  color: #FF5252;
  /*animation: name duration timing-function delay iteration-count direction fill-mode play-state;*/
  animation:  heartpump       //name
              1.5s            //duration
              ease-in-out     //timing-function
              0s              //delay
              infinite        //iteration-count
              alternate       //direction
              none            //fill-mode
              running;        //play-state
`
export const ShareText = styled.div`
  display: flex;
  box-sizing: border-box;
  -webkit-box-flex: 1;
  max-width: none;
  width: auto;
  font-weight: 600;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  flex: 0 0 auto;
`

export const Line = styled.div`
  box-sizing: border-box;
  flex-basis: 0px;
  -webkit-box-flex: 1;
  flex-grow: 1;
  max-width: 100%;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
`

export const LineFill = styled.div`
  margin: 0px 0.5rem;
  flex: 1 1 0%;
  border-bottom: 1px solid;
  color: var(--borders);
`

const IconSocial = `
  display: block;
  height: 3rem;
  width: 3rem;
  margin: .5rem;
`

const ShareHover = `
  transition: color ${V.Transition.default}, transform ${V.Transition.default}; /* Animation */
  &:focus,
  &:hover {
    color: var(--greetings);
    transform: scale(1.1);
  }
`

export const ShareSocialWrapper = styled.div`
  display: flex;
`

export const ShareLinkedin = styled(LinkedinWithCircle)`
  display: block;
  height: 2.6rem;
  width: 2.6rem;
  margin: .5rem;
  ${ShareHover}
`

export const ShareTwitter = styled(Twitter)`
  display: block;
  height: 3.35rem;
  width: 3.35rem;
  margin: .5rem;
  ${ShareHover}
`

export const ShareFacebook = styled(FacebookCircle)`
  ${IconSocial}
  ${ShareHover}
`